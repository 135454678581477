import React, { useState } from "react";
import { Link } from "gatsby";

import Logo from "../../../static/simple-logo.png";

import NavStyles from "./Nav.module.scss";
import HamburgerIcon from "../HamburgerIcon/HamburgerIcon";

const Nav = ({ showNav }) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <nav
      className={`${NavStyles.navBar} ${
        showNav ? NavStyles.showNav : NavStyles.hideNav
      }`}
    >
      <Link
        to="/"
        aria-label="Return to home page"
        className={NavStyles.returnHome}
      >
        <img src={Logo} alt="Emma James Tattoo logo" />
      </Link>
      <ul className={mobileNavOpen ? NavStyles.showMenu : NavStyles.hideMenu}>
        <li>
          <Link to="/about/">
            <span className="spread-underline">About</span>
          </Link>
        </li>
        <li>
          <Link to="/gallery/">
            <span className="spread-underline">Gallery</span>
          </Link>
        </li>
        <li>
          <Link to="/book-now/">
            <span className="spread-underline">Book Now</span>
          </Link>
        </li>
      </ul>
      <button
        type="button"
        className={NavStyles.mobileButton}
        onClick={() => setMobileNavOpen(!mobileNavOpen)}
      >
        <HamburgerIcon
          transformToX={mobileNavOpen}
          parentStyles={NavStyles.mobileIcon}
        />
      </button>
    </nav>
  );
};

export default Nav;
