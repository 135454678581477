import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

const Head = ({ metaContent, location, dynamicTitle }) => {
  const metaData = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteTitle
          titleTemplate
          siteDescription
          siteUrl
          siteImage
        }
      }
    }
  `);

  const { siteTitle, titleTemplate, siteDescription, siteUrl, siteImage } =
    metaData.site.siteMetadata;

  const title = metaContent?.title;
  const description = metaContent?.description;
  const image = metaContent?.image;

  // const dynamicPageTitle = dynamicTitle && `${dynamicTitle} - ${title}`;
  const pageTitle =
    (dynamicTitle && `${dynamicTitle} - ${title}`) || title || siteTitle;

  return (
    <Helmet title={pageTitle} titleTemplate={titleTemplate}>
      <html lang="en" amp />
      <meta name="description" content={description || siteDescription} />
      <meta name="image" content={siteImage} />

      {/* FB Share Info */}
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={location || siteUrl} />
      <meta
        property="og:image"
        content={image ? `https:${image.file.url}` : siteImage}
      />
      <meta property="og:image:alt" content="Emma James Tattoo" />
      <meta
        property="og:description"
        content={description || siteDescription}
      />

      {/* Twitter Share Info */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta
        name="twitter:description"
        content={description || siteDescription}
      />
      <meta
        property="twitter:image"
        content={image ? `https:${image.file.url}` : siteImage}
      />
    </Helmet>
  );
};

export default Head;
