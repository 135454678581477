import React from 'react';
import Nav from '../Nav/Nav';

import LayoutStyles from './Layout.module.scss';

const Layout = ({ children, showNav = true }) => {
  return (
    <div className={LayoutStyles.wrapper}>
      <header>
        <Nav showNav={showNav} />
      </header>
      <main>
        {children}
      </main>
    </div>
  )
}

export default Layout;
